import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ColorLogo from "../images/Savage_Outdoor_Defense_Color_Logo.png"
import Shane from "../images/ShaneSavage.jpg"

const About = () => {
  return (
    <Layout>
      <SEO title="About Us | Savage Outdoor & Defense" />
      <div class="">
        <div class="bg-gray-50 sm:py-16 sm:px-6 ">
          <div class="p-8 bg-gray-800">
            <div class="max-w-full mx-auto">
              <div class="flex justify-center items-center content-center text-center">
                <div class="text-center">
                  <h2 class="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
                    About Us
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="max-w-3xl md:max-w-4xl sm:max-w-full xs:max-w-full mx-auto xs:mx-4 sm:mx-4 md:mx-4 p-4 m-4 bg-white shadow rounded">
              <p class="my-3">
                <img
                  src={Shane}
                  alt="Shane Savage, Owner of Savage Outdoor & Defense"
                  class="w-1/2 float-left p-1 m-2 rounded-md shadow"
                />
                Shane Savage founded Savage Outdoor & Defense in April of 2019.
                Shane’s love for firearms began at age 5 when his father gave
                him a Red Ryder BB gun. Between target practice in the pasture
                and learning to hunt with his dad, Shane learned to respect the
                power of firearms and the importance of handling them safely
                from an early age. While he is an avid firearm enthusiast, Shane
                also has a love for people. Savage Outdoor and Defense was born
                out of a need to connect people with knowledge and resources
                both to protect what is important and to safely enjoy the sport
                of shooting.
              </p>
              <p class="my-3">
                Savage Outdoor & Defense currently specializes in pistol
                training courses, offering private and group instruction for the
                National Rifle Association (NRA) Basics of Pistol Shooting and
                the Texas License to Carry (LTC) course. Shane is an NRA
                certified Pistol Instructor and Range Safety Officer and has
                been accredited by the Texas Department of Public Safety as an
                LTC instructor.
              </p>
              <p class="my-3">
                <strong>Mission Statement</strong>: At Savage Outdoor & Defense
                we are rewriting the narrative of firearms to ensure their safe
                and proper handling through personalized instruction in the
                classroom and excellent marksmanship at the range.
              </p>
              <img
                src={ColorLogo}
                class="mx-auto my-6"
                alt="Savage Outdoor & Defense"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
